import { type Locale } from '@replay/i18n/types/locale';
import {
    PageType,
    PAGE_TYPE_LISTS,
    PAGE_TYPE_UNKNOWN,
    PAGE_TYPE_ACT_WITH_EBU_BOX,
    PAGE_TYPE_CATEGORIES,
    PAGE_TYPE_SUBCATEGORIES,
    PAGE_TYPE_GENRES,
} from '@replay/types/Page';
import { Codes, type Slugs, routes } from '@replay/slugs-generator';

type Taximony = {
    code: Codes | null;
    type: PageType;
    slug: Slugs;
};

type PageTypeByCode = {
    [key in Codes]: PageType;
};

const pageTypeByCode: PageTypeByCode = {
    CATEGORY_ACT: PAGE_TYPE_ACT_WITH_EBU_BOX,
    CATEGORY_CIN: PAGE_TYPE_CATEGORIES,
    CATEGORY_SER: PAGE_TYPE_CATEGORIES,
    CATEGORY_CPO: PAGE_TYPE_CATEGORIES,
    CATEGORY_SCI: PAGE_TYPE_CATEGORIES,
    CATEGORY_DEC: PAGE_TYPE_CATEGORIES,
    CATEGORY_HIS: PAGE_TYPE_CATEGORIES,

    SUBCATEGORY_AJO: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_ENQ: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_KUL: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_DCY: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_JUN: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_AUV: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_FLM: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_CMG: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_CMU: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_MCL: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_ACC: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_SES: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_FIC: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_WEB: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_CHU: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_ART: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_POP: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_IDE: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_MUA: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_MUE: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_HIP: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_MET: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_CLA: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_OPE: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_JAZ: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_MUD: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_ADS: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_BAR: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_SAN: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_ENN: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_TEC: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_ENB: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_NEA: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_EVA: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_ATA: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_VIA: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_XXE: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_CIV: PAGE_TYPE_SUBCATEGORIES,
    SUBCATEGORY_LGP: PAGE_TYPE_SUBCATEGORIES,

    DOR: PAGE_TYPE_GENRES,
    EMI: PAGE_TYPE_GENRES,
    HES: PAGE_TYPE_GENRES,
    SER_NORTH: PAGE_TYPE_GENRES,
    PRODNUM: PAGE_TYPE_GENRES,
    ARTE_CONCERT: PAGE_TYPE_GENRES,
    COLLECTION: PAGE_TYPE_GENRES,

    AVN: PAGE_TYPE_UNKNOWN,
    COLLECTORS: PAGE_TYPE_UNKNOWN,
    EDITORIAL_LAST_CHANCE: PAGE_TYPE_UNKNOWN,
    FESTIVAL_CANNES: PAGE_TYPE_UNKNOWN,
    LIVE: PAGE_TYPE_UNKNOWN,
    MAGAZINES: PAGE_TYPE_UNKNOWN,

    MOST_RECENT: PAGE_TYPE_LISTS,
    MOST_VIEWED: PAGE_TYPE_LISTS,
    LAST_CHANCE: PAGE_TYPE_LISTS,
    ACCESSIBLE_PROGRAMS: PAGE_TYPE_LISTS,
    AUDIO_DESCRIPTION: PAGE_TYPE_LISTS,
    CLOSED_CAPTIONING: PAGE_TYPE_LISTS,
    CLUB_HOME: PAGE_TYPE_LISTS,
    CONCERT_GUIDE: PAGE_TYPE_LISTS,
    TV_GUIDE: PAGE_TYPE_LISTS,
    SEARCH: PAGE_TYPE_LISTS,
    SEARCH_HOME: PAGE_TYPE_LISTS,
    MY_ADVANTAGE: PAGE_TYPE_LISTS,
};

export const getCodeAndTypeFromLocalizedSlug = (locale: Locale, slug: string): Taximony => {
    const remoteType = routes[locale].find((ct) => ct.slug === slug);

    if (remoteType) {
        const pageType = pageTypeByCode[remoteType.code];
        if (pageTypeByCode[remoteType.code] === PAGE_TYPE_UNKNOWN) {
            // todo : notify new-relic
        }
        return { ...remoteType, type: pageType };
    }

    // todo : notify new-relic

    return {
        code: null,
        type: PAGE_TYPE_UNKNOWN,
        slug: slug as Slugs,
    };
};
