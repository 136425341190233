import { Locale } from '@artegeie/page-constants/dist/i18n';
export type Route = {
    code: Codes;
    slug: Slugs;
};

type Routes = Record<Locale, Route[]>;

export type Codes =
    | 'ACCESSIBLE_PROGRAMS'
    | 'ARTE_CONCERT'
    | 'AUDIO_DESCRIPTION'
    | 'AVN'
    | 'CATEGORY_ACT'
    | 'CATEGORY_CIN'
    | 'CATEGORY_CPO'
    | 'CATEGORY_DEC'
    | 'CATEGORY_HIS'
    | 'CATEGORY_SCI'
    | 'CATEGORY_SER'
    | 'CLOSED_CAPTIONING'
    | 'CLUB_HOME'
    | 'COLLECTION'
    | 'COLLECTORS'
    | 'CONCERT_GUIDE'
    | 'DOR'
    | 'EDITORIAL_LAST_CHANCE'
    | 'EMI'
    | 'FESTIVAL_CANNES'
    | 'HES'
    | 'LAST_CHANCE'
    | 'LIVE'
    | 'MAGAZINES'
    | 'MOST_RECENT'
    | 'MOST_VIEWED'
    | 'MY_ADVANTAGE'
    | 'PRODNUM'
    | 'SEARCH'
    | 'SEARCH_HOME'
    | 'SER_NORTH'
    | 'SUBCATEGORY_ACC'
    | 'SUBCATEGORY_ADS'
    | 'SUBCATEGORY_AJO'
    | 'SUBCATEGORY_ART'
    | 'SUBCATEGORY_ATA'
    | 'SUBCATEGORY_AUV'
    | 'SUBCATEGORY_BAR'
    | 'SUBCATEGORY_CHU'
    | 'SUBCATEGORY_CIV'
    | 'SUBCATEGORY_CLA'
    | 'SUBCATEGORY_CMG'
    | 'SUBCATEGORY_CMU'
    | 'SUBCATEGORY_DCY'
    | 'SUBCATEGORY_ENB'
    | 'SUBCATEGORY_ENN'
    | 'SUBCATEGORY_ENQ'
    | 'SUBCATEGORY_EVA'
    | 'SUBCATEGORY_FIC'
    | 'SUBCATEGORY_FLM'
    | 'SUBCATEGORY_HIP'
    | 'SUBCATEGORY_IDE'
    | 'SUBCATEGORY_JAZ'
    | 'SUBCATEGORY_JUN'
    | 'SUBCATEGORY_KUL'
    | 'SUBCATEGORY_LGP'
    | 'SUBCATEGORY_MCL'
    | 'SUBCATEGORY_MET'
    | 'SUBCATEGORY_MUA'
    | 'SUBCATEGORY_MUD'
    | 'SUBCATEGORY_MUE'
    | 'SUBCATEGORY_NEA'
    | 'SUBCATEGORY_OPE'
    | 'SUBCATEGORY_POP'
    | 'SUBCATEGORY_SAN'
    | 'SUBCATEGORY_SES'
    | 'SUBCATEGORY_TEC'
    | 'SUBCATEGORY_VIA'
    | 'SUBCATEGORY_WEB'
    | 'SUBCATEGORY_XXE'
    | 'TV_GUIDE';

export type Slugs =
    | '20th-century'
    | '30-jahre'
    | 'a-la-mesa'
    | 'a-la-une'
    | 'a-table'
    | 'a-venir'
    | 'actualite-du-cinema'
    | 'actualites-culturelles'
    | 'agenda'
    | 'aktuelles'
    | 'aktuelles-und-gesellschaft'
    | 'al-grano'
    | 'ambiente-e-natura'
    | 'analisi'
    | 'analisis'
    | 'analizy'
    | 'arte'
    | 'arte-concert'
    | 'arte-concert_ARTE_CONCERT'
    | 'arte-journal'
    | 'artes'
    | 'artes-escenicas'
    | 'arti-dello-spettacolo'
    | 'arts'
    | 'arts-de-la-scene'
    | 'attualita-culturale'
    | 'autrement-vu'
    | 'bald-offline'
    | 'barockmusik'
    | 'baroque'
    | 'barrierefreie-programme'
    | 'biographien'
    | 'buehnen-performance'
    | 'busqueda-home'
    | 'ciencias'
    | 'cine'
    | 'cinema'
    | 'civilisations'
    | 'civilizaciones'
    | 'civilta'
    | 'clasica'
    | 'classical'
    | 'classique'
    | 'club-home'
    | 'collection-page-collectionid_RC-016485'
    | 'coming-soon-on-artetv'
    | 'con-otros-ojos'
    | 'concert-konzerte-live-und-im-replay'
    | 'concert-plus-de-600-concerts-en-streaming-et-en-acces-libre'
    | 'courts-humoristiques'
    | 'courts-metrages'
    | 'cultura'
    | 'cultura-pop'
    | 'cultura-y-pop'
    | 'culture'
    | 'culture-et-pop'
    | 'culture-pop'
    | 'cywilizacje'
    | 'das-20-jahrhundert'
    | 'decryptages'
    | 'demnachst'
    | 'demnaechst'
    | 'derniere-chance'
    | 'die-zeit-vor-dem-20-jahrhundert'
    | 'digital-productions'
    | 'digitale-produktionen'
    | 'discovery'
    | 'dla-mlodziezy'
    | 'documentaires-et-reportages'
    | 'documentales-y-reportajes'
    | 'documentari-e-reportage'
    | 'documentaries-and-reportage'
    | 'dokumentationen-und-reportagen'
    | 'dokumenty-i-reportaze'
    | 'el-siglo-xx'
    | 'electronic'
    | 'elettronica'
    | 'emisiones'
    | 'emissions'
    | 'en-bref'
    | 'enquetes-et-reportages'
    | 'entdeckung-der-welt'
    | 'environment'
    | 'environnement-et-nature'
    | 'evasion'
    | 'famous-historical-figures'
    | 'fernsehfilme'
    | 'fernsehfilme-und-serien'
    | 'fictions'
    | 'filme'
    | 'filmfestival-cannes'
    | 'filmgroessen'
    | 'films'
    | 'films-muets'
    | 'food'
    | 'geschichte'
    | 'gesundheit-und-medizin'
    | 'grandes-personajes'
    | 'guide'
    | 'heldinnen-in-serie'
    | 'heroines-en-serie'
    | 'hintergrund'
    | 'hip-hop'
    | 'histoire'
    | 'historia'
    | 'history'
    | 'i-grandi-personaggi'
    | 'ideas'
    | 'idee'
    | 'ideenwelten'
    | 'idees'
    | 'in-brief'
    | 'in-pillole'
    | 'inchieste-e-reportage'
    | 'info-et-societe'
    | 'insights'
    | 'investigaciones-y-reportajes'
    | 'investigation-and-reports'
    | 'jak-zyja-inni'
    | 'jazz'
    | 'joven'
    | 'junior'
    | 'kids'
    | 'kino'
    | 'klassik'
    | 'korzysci'
    | 'kulinarik'
    | 'kultur-news'
    | 'kultur-und-pop'
    | 'kultura'
    | 'kunst'
    | 'kurz-und-witzig'
    | 'kurzfilme'
    | 'last-chance'
    | 'leben-anderswo'
    | 'les-dernieres-chances'
    | 'les-grands-du-7e-art'
    | 'les-grands-personnages'
    | 'letzte-chance'
    | 'live-tv-die-programme-von-arte-im-livestream'
    | 'lives-from-afar'
    | 'los-mas-recientes'
    | 'los-ojos-del-arte'
    | 'magazine-shows'
    | 'magazyny'
    | 'mas-vistos'
    | 'medecine-et-sante'
    | 'medicina-e-salute'
    | 'medicina-y-salud'
    | 'medicine-and-health'
    | 'medio-ambiente-y-naturaleza'
    | 'medycyna-i-zdrowie'
    | 'meistgesehen'
    | 'mes-avantages'
    | 'metal'
    | 'mis-ventajas'
    | 'most-recent'
    | 'most-viewed'
    | 'musica-barocca'
    | 'musica-barroca'
    | 'musica-classica'
    | 'musica-electronica'
    | 'musicas-del-mundo'
    | 'musiche-del-mondo'
    | 'musique-baroque'
    | 'musiques-electroniques'
    | 'muzyka-barokowa'
    | 'muzyka-elektroniczna'
    | 'muzyka-klasyczna'
    | 'muzyka-z-calego-swiata'
    | 'my-benefits'
    | 'najczesciej-ogladane'
    | 'najnowsze'
    | 'natur-und-tiere'
    | 'natura-e-animali'
    | 'natura-i-srodowisko'
    | 'naturaleza-y-animales'
    | 'nature-et-animaux'
    | 'nauka'
    | 'neueste-videos'
    | 'oczami-artystow'
    | 'odkrycia'
    | 'oper'
    | 'opera'
    | 'ostatnia-szansa'
    | 'otros-lugares-otras-vidas'
    | 'performing-arts'
    | 'perspektivwechsel'
    | 'piu-recenti'
    | 'piu-visti'
    | 'planet-earth'
    | 'plus-recentes'
    | 'plus-vues'
    | 'podano-do-stolu'
    | 'podroze'
    | 'politica-e-societa'
    | 'politica-y-sociedad'
    | 'politics-and-society'
    | 'polityka-i-spoleczenstwo'
    | 'pop-culture'
    | 'pop-i-rock'
    | 'pop-rock'
    | 'pop-y-rock'
    | 'popkultur'
    | 'popkultura'
    | 'producciones-digitales'
    | 'productions-numeriques'
    | 'produkcje-cyfrowe'
    | 'produzioni-digitali'
    | 'programme-mit-audiodeskription'
    | 'programme-mit-untertiteln'
    | 'programmes'
    | 'programmes-en-audiodescription'
    | 'programmes-en-sous-titrage-sourd-et-malentendant'
    | 'programmi'
    | 'programy'
    | 'pronto-disponible'
    | 'prossimamente'
    | 'przyroda-i-zwierzeta'
    | 'punti-di-vista'
    | 'recherche'
    | 'reisen'
    | 'reportagen-und-recherchen'
    | 'ricerca-home'
    | 'rund-um-den-film'
    | 'sciences'
    | 'scienze'
    | 'search-home'
    | 'sendungen'
    | 'seriale-i-filmy-fabularne'
    | 'seriale-sledcze-i-reportaze'
    | 'serie-e-fiction'
    | 'serien'
    | 'series'
    | 'series-et-fictions'
    | 'series-nordiques'
    | 'series-y-ficciones'
    | 'skandinavische-serien'
    | 'storia'
    | 'stummfilme'
    | 'suche'
    | 'switching-viewpoints'
    | 'sztuka'
    | 'technik-und-innovation'
    | 'technologie-i-innowacje'
    | 'technologies-et-innovations'
    | 'technology-and-innovation'
    | 'tecnologia-e-innovazione'
    | 'tecnologias-e-innovacion'
    | 'through-the-eyes-of-artists'
    | 'travel'
    | 'tv-en-direct-regarder-arte-en-direct-sur-internet'
    | 'ultima-oportunidad'
    | 'ultima-opportunita'
    | 'umwelt-und-natur'
    | 'vantaggi'
    | 'versions-accessibles-des-programmes'
    | 'viaggi'
    | 'viaggi-e-scoperte'
    | 'viajes'
    | 'viajes-y-naturaleza'
    | 'vies-d-ailleurs'
    | 'vite-lontane'
    | 'vorteile'
    | 'voyages-et-decouvertes'
    | 'w-skrocie'
    | 'webserien'
    | 'webseries'
    | 'wissen-kompakt'
    | 'wissenschaft'
    | 'wkrotce-dostepne'
    | 'world'
    | 'wybitne-postacie'
    | 'wystepy-sceniczne'
    | 'wyszukiwanie-home'
    | 'xx-secolo'
    | 'xx-wiek'
    | 'xxe-siecle'
    | 'zmiana-perspektywy';

export const routes: Routes = {
    en: [
        {
            code: 'ARTE_CONCERT',
            slug: 'arte-concert',
        },
        {
            code: 'AVN',
            slug: 'coming-soon-on-artetv',
        },
        {
            code: 'CATEGORY_ACT',
            slug: 'politics-and-society',
        },
        {
            code: 'CATEGORY_CIN',
            slug: 'cinema',
        },
        {
            code: 'CATEGORY_CPO',
            slug: 'culture',
        },
        {
            code: 'CATEGORY_DEC',
            slug: 'discovery',
        },
        {
            code: 'CATEGORY_HIS',
            slug: 'history',
        },
        {
            code: 'CATEGORY_SCI',
            slug: 'sciences',
        },
        {
            code: 'CATEGORY_SER',
            slug: 'series',
        },
        {
            code: 'CLUB_HOME',
            slug: 'club-home',
        },
        {
            code: 'COLLECTION',
            slug: 'collection-page-collectionid_RC-016485',
        },
        {
            code: 'DOR',
            slug: 'documentaries-and-reportage',
        },
        {
            code: 'EMI',
            slug: 'magazine-shows',
        },
        {
            code: 'LAST_CHANCE',
            slug: 'last-chance',
        },
        {
            code: 'MAGAZINES',
            slug: 'programmes',
        },
        {
            code: 'MOST_RECENT',
            slug: 'most-recent',
        },
        {
            code: 'MOST_VIEWED',
            slug: 'most-viewed',
        },
        {
            code: 'MY_ADVANTAGE',
            slug: 'my-benefits',
        },
        {
            code: 'PRODNUM',
            slug: 'digital-productions',
        },
        {
            code: 'SEARCH_HOME',
            slug: 'search-home',
        },
        {
            code: 'SUBCATEGORY_ADS',
            slug: 'performing-arts',
        },
        {
            code: 'SUBCATEGORY_AJO',
            slug: 'arte-journal',
        },
        {
            code: 'SUBCATEGORY_ART',
            slug: 'arts',
        },
        {
            code: 'SUBCATEGORY_ATA',
            slug: 'food',
        },
        {
            code: 'SUBCATEGORY_AUV',
            slug: 'switching-viewpoints',
        },
        {
            code: 'SUBCATEGORY_BAR',
            slug: 'baroque',
        },
        {
            code: 'SUBCATEGORY_CIV',
            slug: 'civilisations',
        },
        {
            code: 'SUBCATEGORY_CLA',
            slug: 'classical',
        },
        {
            code: 'SUBCATEGORY_DCY',
            slug: 'insights',
        },
        {
            code: 'SUBCATEGORY_ENB',
            slug: 'in-brief',
        },
        {
            code: 'SUBCATEGORY_ENN',
            slug: 'environment',
        },
        {
            code: 'SUBCATEGORY_ENQ',
            slug: 'investigation-and-reports',
        },
        {
            code: 'SUBCATEGORY_EVA',
            slug: 'travel',
        },
        {
            code: 'SUBCATEGORY_HIP',
            slug: 'hip-hop',
        },
        {
            code: 'SUBCATEGORY_IDE',
            slug: 'ideas',
        },
        {
            code: 'SUBCATEGORY_JAZ',
            slug: 'jazz',
        },
        {
            code: 'SUBCATEGORY_JUN',
            slug: 'kids',
        },
        {
            code: 'SUBCATEGORY_KUL',
            slug: 'through-the-eyes-of-artists',
        },
        {
            code: 'SUBCATEGORY_LGP',
            slug: 'famous-historical-figures',
        },
        {
            code: 'SUBCATEGORY_MET',
            slug: 'metal',
        },
        {
            code: 'SUBCATEGORY_MUA',
            slug: 'pop-rock',
        },
        {
            code: 'SUBCATEGORY_MUD',
            slug: 'world',
        },
        {
            code: 'SUBCATEGORY_MUE',
            slug: 'electronic',
        },
        {
            code: 'SUBCATEGORY_NEA',
            slug: 'planet-earth',
        },
        {
            code: 'SUBCATEGORY_OPE',
            slug: 'opera',
        },
        {
            code: 'SUBCATEGORY_POP',
            slug: 'pop-culture',
        },
        {
            code: 'SUBCATEGORY_SAN',
            slug: 'medicine-and-health',
        },
        {
            code: 'SUBCATEGORY_TEC',
            slug: 'technology-and-innovation',
        },
        {
            code: 'SUBCATEGORY_VIA',
            slug: 'lives-from-afar',
        },
        {
            code: 'SUBCATEGORY_XXE',
            slug: '20th-century',
        },
    ],
    it: [
        {
            code: 'ARTE_CONCERT',
            slug: 'arte-concert_ARTE_CONCERT',
        },
        {
            code: 'AVN',
            slug: 'prossimamente',
        },
        {
            code: 'CATEGORY_ACT',
            slug: 'politica-e-societa',
        },
        {
            code: 'CATEGORY_CIN',
            slug: 'cinema',
        },
        {
            code: 'CATEGORY_CPO',
            slug: 'cultura',
        },
        {
            code: 'CATEGORY_DEC',
            slug: 'viaggi-e-scoperte',
        },
        {
            code: 'CATEGORY_HIS',
            slug: 'storia',
        },
        {
            code: 'CATEGORY_SCI',
            slug: 'scienze',
        },
        {
            code: 'CATEGORY_SER',
            slug: 'serie-e-fiction',
        },
        {
            code: 'CLUB_HOME',
            slug: 'club-home',
        },
        {
            code: 'COLLECTION',
            slug: 'collection-page-collectionid_RC-016485',
        },
        {
            code: 'DOR',
            slug: 'documentari-e-reportage',
        },
        {
            code: 'EMI',
            slug: 'programmi',
        },
        {
            code: 'LAST_CHANCE',
            slug: 'ultima-opportunita',
        },
        {
            code: 'MAGAZINES',
            slug: 'programmes',
        },
        {
            code: 'MOST_RECENT',
            slug: 'piu-recenti',
        },
        {
            code: 'MOST_VIEWED',
            slug: 'piu-visti',
        },
        {
            code: 'MY_ADVANTAGE',
            slug: 'vantaggi',
        },
        {
            code: 'PRODNUM',
            slug: 'produzioni-digitali',
        },
        {
            code: 'SEARCH_HOME',
            slug: 'ricerca-home',
        },
        {
            code: 'SUBCATEGORY_ADS',
            slug: 'arti-dello-spettacolo',
        },
        {
            code: 'SUBCATEGORY_AJO',
            slug: 'arte-journal',
        },
        {
            code: 'SUBCATEGORY_ART',
            slug: 'arte',
        },
        {
            code: 'SUBCATEGORY_ATA',
            slug: 'food',
        },
        {
            code: 'SUBCATEGORY_AUV',
            slug: 'punti-di-vista',
        },
        {
            code: 'SUBCATEGORY_BAR',
            slug: 'musica-barocca',
        },
        {
            code: 'SUBCATEGORY_CIV',
            slug: 'civilta',
        },
        {
            code: 'SUBCATEGORY_CLA',
            slug: 'musica-classica',
        },
        {
            code: 'SUBCATEGORY_DCY',
            slug: 'analisi',
        },
        {
            code: 'SUBCATEGORY_ENB',
            slug: 'in-pillole',
        },
        {
            code: 'SUBCATEGORY_ENN',
            slug: 'ambiente-e-natura',
        },
        {
            code: 'SUBCATEGORY_ENQ',
            slug: 'inchieste-e-reportage',
        },
        {
            code: 'SUBCATEGORY_EVA',
            slug: 'viaggi',
        },
        {
            code: 'SUBCATEGORY_HIP',
            slug: 'hip-hop',
        },
        {
            code: 'SUBCATEGORY_IDE',
            slug: 'idee',
        },
        {
            code: 'SUBCATEGORY_JAZ',
            slug: 'jazz',
        },
        {
            code: 'SUBCATEGORY_JUN',
            slug: 'junior',
        },
        {
            code: 'SUBCATEGORY_KUL',
            slug: 'attualita-culturale',
        },
        {
            code: 'SUBCATEGORY_LGP',
            slug: 'i-grandi-personaggi',
        },
        {
            code: 'SUBCATEGORY_MET',
            slug: 'metal',
        },
        {
            code: 'SUBCATEGORY_MUA',
            slug: 'pop-rock',
        },
        {
            code: 'SUBCATEGORY_MUD',
            slug: 'musiche-del-mondo',
        },
        {
            code: 'SUBCATEGORY_MUE',
            slug: 'elettronica',
        },
        {
            code: 'SUBCATEGORY_NEA',
            slug: 'natura-e-animali',
        },
        {
            code: 'SUBCATEGORY_OPE',
            slug: 'opera',
        },
        {
            code: 'SUBCATEGORY_POP',
            slug: 'cultura-pop',
        },
        {
            code: 'SUBCATEGORY_SAN',
            slug: 'medicina-e-salute',
        },
        {
            code: 'SUBCATEGORY_TEC',
            slug: 'tecnologia-e-innovazione',
        },
        {
            code: 'SUBCATEGORY_VIA',
            slug: 'vite-lontane',
        },
        {
            code: 'SUBCATEGORY_XXE',
            slug: 'xx-secolo',
        },
    ],
    pl: [
        {
            code: 'ARTE_CONCERT',
            slug: 'arte-concert',
        },
        {
            code: 'AVN',
            slug: 'wkrotce-dostepne',
        },
        {
            code: 'CATEGORY_ACT',
            slug: 'polityka-i-spoleczenstwo',
        },
        {
            code: 'CATEGORY_CIN',
            slug: 'kino',
        },
        {
            code: 'CATEGORY_CPO',
            slug: 'kultura',
        },
        {
            code: 'CATEGORY_DEC',
            slug: 'odkrycia',
        },
        {
            code: 'CATEGORY_HIS',
            slug: 'historia',
        },
        {
            code: 'CATEGORY_SCI',
            slug: 'nauka',
        },
        {
            code: 'CATEGORY_SER',
            slug: 'seriale-i-filmy-fabularne',
        },
        {
            code: 'CLUB_HOME',
            slug: 'club-home',
        },
        {
            code: 'COLLECTION',
            slug: 'collection-page-collectionid_RC-016485',
        },
        {
            code: 'DOR',
            slug: 'dokumenty-i-reportaze',
        },
        {
            code: 'EMI',
            slug: 'magazyny',
        },
        {
            code: 'LAST_CHANCE',
            slug: 'ostatnia-szansa',
        },
        {
            code: 'MAGAZINES',
            slug: 'programy',
        },
        {
            code: 'MOST_RECENT',
            slug: 'najnowsze',
        },
        {
            code: 'MOST_VIEWED',
            slug: 'najczesciej-ogladane',
        },
        {
            code: 'MY_ADVANTAGE',
            slug: 'korzysci',
        },
        {
            code: 'PRODNUM',
            slug: 'produkcje-cyfrowe',
        },
        {
            code: 'SEARCH_HOME',
            slug: 'wyszukiwanie-home',
        },
        {
            code: 'SUBCATEGORY_ADS',
            slug: 'wystepy-sceniczne',
        },
        {
            code: 'SUBCATEGORY_AJO',
            slug: 'arte-journal',
        },
        {
            code: 'SUBCATEGORY_ART',
            slug: 'sztuka',
        },
        {
            code: 'SUBCATEGORY_ATA',
            slug: 'podano-do-stolu',
        },
        {
            code: 'SUBCATEGORY_AUV',
            slug: 'zmiana-perspektywy',
        },
        {
            code: 'SUBCATEGORY_BAR',
            slug: 'muzyka-barokowa',
        },
        {
            code: 'SUBCATEGORY_CIV',
            slug: 'cywilizacje',
        },
        {
            code: 'SUBCATEGORY_CLA',
            slug: 'muzyka-klasyczna',
        },
        {
            code: 'SUBCATEGORY_DCY',
            slug: 'analizy',
        },
        {
            code: 'SUBCATEGORY_ENB',
            slug: 'w-skrocie',
        },
        {
            code: 'SUBCATEGORY_ENN',
            slug: 'natura-i-srodowisko',
        },
        {
            code: 'SUBCATEGORY_ENQ',
            slug: 'seriale-sledcze-i-reportaze',
        },
        {
            code: 'SUBCATEGORY_EVA',
            slug: 'podroze',
        },
        {
            code: 'SUBCATEGORY_HIP',
            slug: 'hip-hop',
        },
        {
            code: 'SUBCATEGORY_IDE',
            slug: 'idee',
        },
        {
            code: 'SUBCATEGORY_JAZ',
            slug: 'jazz',
        },
        {
            code: 'SUBCATEGORY_JUN',
            slug: 'dla-mlodziezy',
        },
        {
            code: 'SUBCATEGORY_KUL',
            slug: 'oczami-artystow',
        },
        {
            code: 'SUBCATEGORY_LGP',
            slug: 'wybitne-postacie',
        },
        {
            code: 'SUBCATEGORY_MET',
            slug: 'metal',
        },
        {
            code: 'SUBCATEGORY_MUA',
            slug: 'pop-i-rock',
        },
        {
            code: 'SUBCATEGORY_MUD',
            slug: 'muzyka-z-calego-swiata',
        },
        {
            code: 'SUBCATEGORY_MUE',
            slug: 'muzyka-elektroniczna',
        },
        {
            code: 'SUBCATEGORY_NEA',
            slug: 'przyroda-i-zwierzeta',
        },
        {
            code: 'SUBCATEGORY_OPE',
            slug: 'opera',
        },
        {
            code: 'SUBCATEGORY_POP',
            slug: 'popkultura',
        },
        {
            code: 'SUBCATEGORY_SAN',
            slug: 'medycyna-i-zdrowie',
        },
        {
            code: 'SUBCATEGORY_TEC',
            slug: 'technologie-i-innowacje',
        },
        {
            code: 'SUBCATEGORY_VIA',
            slug: 'jak-zyja-inni',
        },
        {
            code: 'SUBCATEGORY_XXE',
            slug: 'xx-wiek',
        },
    ],
    de: [
        {
            code: 'ACCESSIBLE_PROGRAMS',
            slug: 'barrierefreie-programme',
        },
        {
            code: 'ARTE_CONCERT',
            slug: 'concert-konzerte-live-und-im-replay',
        },
        {
            code: 'AUDIO_DESCRIPTION',
            slug: 'programme-mit-audiodeskription',
        },
        {
            code: 'AVN',
            slug: 'demnaechst',
        },
        {
            code: 'CATEGORY_ACT',
            slug: 'aktuelles-und-gesellschaft',
        },
        {
            code: 'CATEGORY_CIN',
            slug: 'kino',
        },
        {
            code: 'CATEGORY_CPO',
            slug: 'kultur-und-pop',
        },
        {
            code: 'CATEGORY_DEC',
            slug: 'entdeckung-der-welt',
        },
        {
            code: 'CATEGORY_HIS',
            slug: 'geschichte',
        },
        {
            code: 'CATEGORY_SCI',
            slug: 'wissenschaft',
        },
        {
            code: 'CATEGORY_SER',
            slug: 'fernsehfilme-und-serien',
        },
        {
            code: 'CLOSED_CAPTIONING',
            slug: 'programme-mit-untertiteln',
        },
        {
            code: 'CLUB_HOME',
            slug: 'club-home',
        },
        {
            code: 'COLLECTION',
            slug: 'collection-page-collectionid_RC-016485',
        },
        {
            code: 'COLLECTORS',
            slug: '30-jahre',
        },
        {
            code: 'CONCERT_GUIDE',
            slug: 'demnachst',
        },
        {
            code: 'DOR',
            slug: 'dokumentationen-und-reportagen',
        },
        {
            code: 'EDITORIAL_LAST_CHANCE',
            slug: 'bald-offline',
        },
        {
            code: 'EMI',
            slug: 'sendungen',
        },
        {
            code: 'FESTIVAL_CANNES',
            slug: 'filmfestival-cannes',
        },
        {
            code: 'HES',
            slug: 'heldinnen-in-serie',
        },
        {
            code: 'LAST_CHANCE',
            slug: 'letzte-chance',
        },
        {
            code: 'LIVE',
            slug: 'live-tv-die-programme-von-arte-im-livestream',
        },
        {
            code: 'MAGAZINES',
            slug: 'sendungen',
        },
        {
            code: 'MOST_RECENT',
            slug: 'neueste-videos',
        },
        {
            code: 'MOST_VIEWED',
            slug: 'meistgesehen',
        },
        {
            code: 'MY_ADVANTAGE',
            slug: 'vorteile',
        },
        {
            code: 'PRODNUM',
            slug: 'digitale-produktionen',
        },
        {
            code: 'SEARCH',
            slug: 'suche',
        },
        {
            code: 'SEARCH_HOME',
            slug: 'suche',
        },
        {
            code: 'SER_NORTH',
            slug: 'skandinavische-serien',
        },
        {
            code: 'SUBCATEGORY_ACC',
            slug: 'rund-um-den-film',
        },
        {
            code: 'SUBCATEGORY_ADS',
            slug: 'buehnen-performance',
        },
        {
            code: 'SUBCATEGORY_AJO',
            slug: 'aktuelles',
        },
        {
            code: 'SUBCATEGORY_ART',
            slug: 'kunst',
        },
        {
            code: 'SUBCATEGORY_ATA',
            slug: 'kulinarik',
        },
        {
            code: 'SUBCATEGORY_AUV',
            slug: 'perspektivwechsel',
        },
        {
            code: 'SUBCATEGORY_BAR',
            slug: 'barockmusik',
        },
        {
            code: 'SUBCATEGORY_CHU',
            slug: 'kurz-und-witzig',
        },
        {
            code: 'SUBCATEGORY_CIV',
            slug: 'die-zeit-vor-dem-20-jahrhundert',
        },
        {
            code: 'SUBCATEGORY_CLA',
            slug: 'klassik',
        },
        {
            code: 'SUBCATEGORY_CMG',
            slug: 'kurzfilme',
        },
        {
            code: 'SUBCATEGORY_CMU',
            slug: 'stummfilme',
        },
        {
            code: 'SUBCATEGORY_DCY',
            slug: 'hintergrund',
        },
        {
            code: 'SUBCATEGORY_ENB',
            slug: 'wissen-kompakt',
        },
        {
            code: 'SUBCATEGORY_ENN',
            slug: 'umwelt-und-natur',
        },
        {
            code: 'SUBCATEGORY_ENQ',
            slug: 'reportagen-und-recherchen',
        },
        {
            code: 'SUBCATEGORY_EVA',
            slug: 'reisen',
        },
        {
            code: 'SUBCATEGORY_FIC',
            slug: 'fernsehfilme',
        },
        {
            code: 'SUBCATEGORY_FLM',
            slug: 'filme',
        },
        {
            code: 'SUBCATEGORY_HIP',
            slug: 'hip-hop',
        },
        {
            code: 'SUBCATEGORY_IDE',
            slug: 'ideenwelten',
        },
        {
            code: 'SUBCATEGORY_JAZ',
            slug: 'jazz',
        },
        {
            code: 'SUBCATEGORY_JUN',
            slug: 'junior',
        },
        {
            code: 'SUBCATEGORY_KUL',
            slug: 'kultur-news',
        },
        {
            code: 'SUBCATEGORY_LGP',
            slug: 'biographien',
        },
        {
            code: 'SUBCATEGORY_MCL',
            slug: 'filmgroessen',
        },
        {
            code: 'SUBCATEGORY_MET',
            slug: 'metal',
        },
        {
            code: 'SUBCATEGORY_MUA',
            slug: 'pop-rock',
        },
        {
            code: 'SUBCATEGORY_MUD',
            slug: 'world',
        },
        {
            code: 'SUBCATEGORY_MUE',
            slug: 'electronic',
        },
        {
            code: 'SUBCATEGORY_NEA',
            slug: 'natur-und-tiere',
        },
        {
            code: 'SUBCATEGORY_OPE',
            slug: 'oper',
        },
        {
            code: 'SUBCATEGORY_POP',
            slug: 'popkultur',
        },
        {
            code: 'SUBCATEGORY_SAN',
            slug: 'gesundheit-und-medizin',
        },
        {
            code: 'SUBCATEGORY_SES',
            slug: 'serien',
        },
        {
            code: 'SUBCATEGORY_TEC',
            slug: 'technik-und-innovation',
        },
        {
            code: 'SUBCATEGORY_VIA',
            slug: 'leben-anderswo',
        },
        {
            code: 'SUBCATEGORY_WEB',
            slug: 'webserien',
        },
        {
            code: 'SUBCATEGORY_XXE',
            slug: 'das-20-jahrhundert',
        },
        {
            code: 'TV_GUIDE',
            slug: 'guide',
        },
    ],
    fr: [
        {
            code: 'ACCESSIBLE_PROGRAMS',
            slug: 'versions-accessibles-des-programmes',
        },
        {
            code: 'ARTE_CONCERT',
            slug: 'concert-plus-de-600-concerts-en-streaming-et-en-acces-libre',
        },
        {
            code: 'AUDIO_DESCRIPTION',
            slug: 'programmes-en-audiodescription',
        },
        {
            code: 'AVN',
            slug: 'a-venir',
        },
        {
            code: 'CATEGORY_ACT',
            slug: 'info-et-societe',
        },
        {
            code: 'CATEGORY_CIN',
            slug: 'cinema',
        },
        {
            code: 'CATEGORY_CPO',
            slug: 'culture-et-pop',
        },
        {
            code: 'CATEGORY_DEC',
            slug: 'voyages-et-decouvertes',
        },
        {
            code: 'CATEGORY_HIS',
            slug: 'histoire',
        },
        {
            code: 'CATEGORY_SCI',
            slug: 'sciences',
        },
        {
            code: 'CATEGORY_SER',
            slug: 'series-et-fictions',
        },
        {
            code: 'CLOSED_CAPTIONING',
            slug: 'programmes-en-sous-titrage-sourd-et-malentendant',
        },
        {
            code: 'CLUB_HOME',
            slug: 'club-home',
        },
        {
            code: 'COLLECTION',
            slug: 'collection-page-collectionid_RC-016485',
        },
        {
            code: 'CONCERT_GUIDE',
            slug: 'agenda',
        },
        {
            code: 'DOR',
            slug: 'documentaires-et-reportages',
        },
        {
            code: 'EDITORIAL_LAST_CHANCE',
            slug: 'les-dernieres-chances',
        },
        {
            code: 'EMI',
            slug: 'emissions',
        },
        {
            code: 'HES',
            slug: 'heroines-en-serie',
        },
        {
            code: 'LAST_CHANCE',
            slug: 'derniere-chance',
        },
        {
            code: 'LIVE',
            slug: 'tv-en-direct-regarder-arte-en-direct-sur-internet',
        },
        {
            code: 'MAGAZINES',
            slug: 'emissions',
        },
        {
            code: 'MOST_RECENT',
            slug: 'plus-recentes',
        },
        {
            code: 'MOST_VIEWED',
            slug: 'plus-vues',
        },
        {
            code: 'MY_ADVANTAGE',
            slug: 'mes-avantages',
        },
        {
            code: 'PRODNUM',
            slug: 'productions-numeriques',
        },
        {
            code: 'SEARCH',
            slug: 'recherche',
        },
        {
            code: 'SEARCH_HOME',
            slug: 'recherche',
        },
        {
            code: 'SER_NORTH',
            slug: 'series-nordiques',
        },
        {
            code: 'SUBCATEGORY_ACC',
            slug: 'actualite-du-cinema',
        },
        {
            code: 'SUBCATEGORY_ADS',
            slug: 'arts-de-la-scene',
        },
        {
            code: 'SUBCATEGORY_AJO',
            slug: 'a-la-une',
        },
        {
            code: 'SUBCATEGORY_ART',
            slug: 'arts',
        },
        {
            code: 'SUBCATEGORY_ATA',
            slug: 'a-table',
        },
        {
            code: 'SUBCATEGORY_AUV',
            slug: 'autrement-vu',
        },
        {
            code: 'SUBCATEGORY_BAR',
            slug: 'musique-baroque',
        },
        {
            code: 'SUBCATEGORY_CHU',
            slug: 'courts-humoristiques',
        },
        {
            code: 'SUBCATEGORY_CIV',
            slug: 'civilisations',
        },
        {
            code: 'SUBCATEGORY_CLA',
            slug: 'classique',
        },
        {
            code: 'SUBCATEGORY_CMG',
            slug: 'courts-metrages',
        },
        {
            code: 'SUBCATEGORY_CMU',
            slug: 'films-muets',
        },
        {
            code: 'SUBCATEGORY_DCY',
            slug: 'decryptages',
        },
        {
            code: 'SUBCATEGORY_ENB',
            slug: 'en-bref',
        },
        {
            code: 'SUBCATEGORY_ENN',
            slug: 'environnement-et-nature',
        },
        {
            code: 'SUBCATEGORY_ENQ',
            slug: 'enquetes-et-reportages',
        },
        {
            code: 'SUBCATEGORY_EVA',
            slug: 'evasion',
        },
        {
            code: 'SUBCATEGORY_FIC',
            slug: 'fictions',
        },
        {
            code: 'SUBCATEGORY_FLM',
            slug: 'films',
        },
        {
            code: 'SUBCATEGORY_HIP',
            slug: 'hip-hop',
        },
        {
            code: 'SUBCATEGORY_IDE',
            slug: 'idees',
        },
        {
            code: 'SUBCATEGORY_JAZ',
            slug: 'jazz',
        },
        {
            code: 'SUBCATEGORY_JUN',
            slug: 'junior',
        },
        {
            code: 'SUBCATEGORY_KUL',
            slug: 'actualites-culturelles',
        },
        {
            code: 'SUBCATEGORY_LGP',
            slug: 'les-grands-personnages',
        },
        {
            code: 'SUBCATEGORY_MCL',
            slug: 'les-grands-du-7e-art',
        },
        {
            code: 'SUBCATEGORY_MET',
            slug: 'metal',
        },
        {
            code: 'SUBCATEGORY_MUA',
            slug: 'pop-rock',
        },
        {
            code: 'SUBCATEGORY_MUD',
            slug: 'world',
        },
        {
            code: 'SUBCATEGORY_MUE',
            slug: 'musiques-electroniques',
        },
        {
            code: 'SUBCATEGORY_NEA',
            slug: 'nature-et-animaux',
        },
        {
            code: 'SUBCATEGORY_OPE',
            slug: 'opera',
        },
        {
            code: 'SUBCATEGORY_POP',
            slug: 'culture-pop',
        },
        {
            code: 'SUBCATEGORY_SAN',
            slug: 'medecine-et-sante',
        },
        {
            code: 'SUBCATEGORY_SES',
            slug: 'series',
        },
        {
            code: 'SUBCATEGORY_TEC',
            slug: 'technologies-et-innovations',
        },
        {
            code: 'SUBCATEGORY_VIA',
            slug: 'vies-d-ailleurs',
        },
        {
            code: 'SUBCATEGORY_WEB',
            slug: 'webseries',
        },
        {
            code: 'SUBCATEGORY_XXE',
            slug: 'xxe-siecle',
        },
        {
            code: 'TV_GUIDE',
            slug: 'guide',
        },
    ],
    es: [
        {
            code: 'ARTE_CONCERT',
            slug: 'arte-concert',
        },
        {
            code: 'AVN',
            slug: 'pronto-disponible',
        },
        {
            code: 'CATEGORY_ACT',
            slug: 'politica-y-sociedad',
        },
        {
            code: 'CATEGORY_CIN',
            slug: 'cine',
        },
        {
            code: 'CATEGORY_CPO',
            slug: 'cultura-y-pop',
        },
        {
            code: 'CATEGORY_DEC',
            slug: 'viajes-y-naturaleza',
        },
        {
            code: 'CATEGORY_HIS',
            slug: 'historia',
        },
        {
            code: 'CATEGORY_SCI',
            slug: 'ciencias',
        },
        {
            code: 'CATEGORY_SER',
            slug: 'series-y-ficciones',
        },
        {
            code: 'CLUB_HOME',
            slug: 'club-home',
        },
        {
            code: 'COLLECTION',
            slug: 'collection-page-collectionid_RC-016485',
        },
        {
            code: 'DOR',
            slug: 'documentales-y-reportajes',
        },
        {
            code: 'EMI',
            slug: 'emisiones',
        },
        {
            code: 'LAST_CHANCE',
            slug: 'ultima-oportunidad',
        },
        {
            code: 'MAGAZINES',
            slug: 'emisiones',
        },
        {
            code: 'MOST_RECENT',
            slug: 'los-mas-recientes',
        },
        {
            code: 'MOST_VIEWED',
            slug: 'mas-vistos',
        },
        {
            code: 'MY_ADVANTAGE',
            slug: 'mis-ventajas',
        },
        {
            code: 'PRODNUM',
            slug: 'producciones-digitales',
        },
        {
            code: 'SEARCH_HOME',
            slug: 'busqueda-home',
        },
        {
            code: 'SUBCATEGORY_ADS',
            slug: 'artes-escenicas',
        },
        {
            code: 'SUBCATEGORY_AJO',
            slug: 'arte-journal',
        },
        {
            code: 'SUBCATEGORY_ART',
            slug: 'artes',
        },
        {
            code: 'SUBCATEGORY_ATA',
            slug: 'a-la-mesa',
        },
        {
            code: 'SUBCATEGORY_AUV',
            slug: 'con-otros-ojos',
        },
        {
            code: 'SUBCATEGORY_BAR',
            slug: 'musica-barroca',
        },
        {
            code: 'SUBCATEGORY_CIV',
            slug: 'civilizaciones',
        },
        {
            code: 'SUBCATEGORY_CLA',
            slug: 'clasica',
        },
        {
            code: 'SUBCATEGORY_DCY',
            slug: 'analisis',
        },
        {
            code: 'SUBCATEGORY_ENB',
            slug: 'al-grano',
        },
        {
            code: 'SUBCATEGORY_ENN',
            slug: 'medio-ambiente-y-naturaleza',
        },
        {
            code: 'SUBCATEGORY_ENQ',
            slug: 'investigaciones-y-reportajes',
        },
        {
            code: 'SUBCATEGORY_EVA',
            slug: 'viajes',
        },
        {
            code: 'SUBCATEGORY_HIP',
            slug: 'hip-hop',
        },
        {
            code: 'SUBCATEGORY_IDE',
            slug: 'ideas',
        },
        {
            code: 'SUBCATEGORY_JAZ',
            slug: 'jazz',
        },
        {
            code: 'SUBCATEGORY_JUN',
            slug: 'joven',
        },
        {
            code: 'SUBCATEGORY_KUL',
            slug: 'los-ojos-del-arte',
        },
        {
            code: 'SUBCATEGORY_LGP',
            slug: 'grandes-personajes',
        },
        {
            code: 'SUBCATEGORY_MET',
            slug: 'metal',
        },
        {
            code: 'SUBCATEGORY_MUA',
            slug: 'pop-y-rock',
        },
        {
            code: 'SUBCATEGORY_MUD',
            slug: 'musicas-del-mundo',
        },
        {
            code: 'SUBCATEGORY_MUE',
            slug: 'musica-electronica',
        },
        {
            code: 'SUBCATEGORY_NEA',
            slug: 'naturaleza-y-animales',
        },
        {
            code: 'SUBCATEGORY_OPE',
            slug: 'opera',
        },
        {
            code: 'SUBCATEGORY_POP',
            slug: 'cultura-pop',
        },
        {
            code: 'SUBCATEGORY_SAN',
            slug: 'medicina-y-salud',
        },
        {
            code: 'SUBCATEGORY_TEC',
            slug: 'tecnologias-e-innovacion',
        },
        {
            code: 'SUBCATEGORY_VIA',
            slug: 'otros-lugares-otras-vidas',
        },
        {
            code: 'SUBCATEGORY_XXE',
            slug: 'el-siglo-xx',
        },
    ],
};
