import newrelic from 'newrelic';
import Script from 'next/script';
import React, { type ReactElement } from 'react';
import { getEbuBoxScript } from '../../domains/videos/ebuBox';

import { type GetServerSidePropsContext } from 'next';
import { getProgramFromZones } from '../../domains/program/getProgramFromZones';
import { fetchVideoData, type ResultVideoFetch, useVideoData } from '../../domains/videos/fetchVideoData';
import { resolveParamsFromVideosIdentifiers } from '../../domains/videos/resolveParamsFromVideosIdentifiers';
import { VideosRouter } from '../../domains/videos/VideosRouter';
import { env } from '@/env';
import { translateFactory, loadPhrases } from '@replay/i18n';
import { getAbGroupsFromRequest } from '../../shared/abTesting/abGroupParser';
import { Error } from '../../shared/Error';
import getGeoblockingFromRequest from '../../shared/getGeoblockingFromRequest';
import { isLocale, Locale } from '@replay/i18n/types/locale';
import { type EmacServerSideProps, type InferPageProps } from '@replay/types/MyGetServerSidePropsResult';
import { type Page, type PageType, PAGE_TYPE_PROGRAMS, PAGE_TYPE_PROGRAMS_TRAILER } from '@replay/types/Page';
import { isError, isOk } from '@replay/types/Result';
import { getHttpStatusCode } from '@replay/types/Type_Error';
import { ensurePreviewDate } from '../../shared/fetchPageData';
import { fetchProgramPlayerData } from '../../shared/api-player/server';
import { type ApiPlayerConfigData, type ApiPlayerPlaylistItems } from '../../shared/api-player/commons';
import { getValidatedAgeFromRequest } from '../../shared/getValidatedAgeFromRequest';
import { type PageCode } from '@replay/types/Restrictions';
import { IncomingMessage } from 'http';

type Props = {
    geoblocking: string | null;
    initialType: PageType;
    redirect?: string;
    mamiBaseUrl: string;
    tcStartFrom: number | null;
    retryCount: number | null;
    abvGroups: string;
    forceTrailer?: boolean;
    apiPlayerConfig: ApiPlayerConfigData | null;
    apiPlayerPlaylist: ApiPlayerPlaylistItems | null;
};

const shouldRedirectToTheRightSlug = (identifiers: string[], data: Page, type: string): boolean => {
    // For a program or a collection
    // slug is missing or url contains unused identifier after slug

    if ((identifiers.length === 1 || identifiers.length > 2) && data && ['collections', 'programs'].includes(type)) {
        return !!data.slug;
    }

    return (
        identifiers.length === 2 && data && ['collections', 'programs'].includes(type) && identifiers[1] !== data.slug
    );
};

const ensureQueryIdentifiers = (identifiers: string | string[] | undefined): string[] => {
    if (!identifiers) {
        return [];
    }
    return Array.isArray(identifiers) ? identifiers : [identifiers];
};

const noDataError = (locale: Locale): GlobalError['VideoPage'] => ({
    type: 'VideoPage',
    err: {
        type: 'NoData',
        locale: locale,
    },
});

export default function Videos({ props, locale, domain }: InferPageProps<typeof getServerSideProps>): ReactElement {
    const {
        geoblocking,
        page,
        initialType,
        mamiBaseUrl,
        tcStartFrom,
        retryCount,
        abvGroups,
        forceTrailer,
        apiPlayerConfig,
        apiPlayerPlaylist,
    } = props;
    const initialData: ResultVideoFetch = page;
    const response = useVideoData({
        geoblocking,
        initialData,
        locale,
        abvGroups,
    });

    const ebuBoxScriptSrc = getEbuBoxScript(initialType);
    const ebuBox = ebuBoxScriptSrc && <Script src={ebuBoxScriptSrc} strategy="lazyOnload" />;

    if (!response.data || isError(response.data)) {
        if (response.data && isError(response.data) && isError(page)) {
            return <Error error={response.data.value || noDataError(locale)} />;
        }

        return (
            <>
                {ebuBox}
                <VideosRouter
                    data={isOk(page) ? page.value : null}
                    type={initialType}
                    mamiBaseUrl={mamiBaseUrl}
                    locale={locale}
                    tcStartFrom={tcStartFrom}
                    retryCount={retryCount}
                    forceTrailer={forceTrailer}
                    domain={domain}
                    apiPlayerConfig={apiPlayerConfig}
                    apiPlayerPlaylist={apiPlayerPlaylist}
                />
            </>
        );
    }

    const data = response.data.value;
    const type = initialType;
    return (
        <>
            {ebuBox}
            <VideosRouter
                data={data}
                type={type}
                mamiBaseUrl={mamiBaseUrl}
                locale={locale}
                tcStartFrom={tcStartFrom}
                retryCount={retryCount}
                forceTrailer={forceTrailer}
                domain={domain}
                apiPlayerConfig={apiPlayerConfig}
                apiPlayerPlaylist={apiPlayerPlaylist}
            />
        </>
    );
}

export const extractTcStartFromFromQuery = (rawTimeCode: string | undefined): number | null => {
    if (!rawTimeCode) {
        return null;
    }

    const tcStartFrom = parseInt(rawTimeCode, 10);
    return isNaN(tcStartFrom) ? null : tcStartFrom;
};

const extractRetryCountFromQuery = (retryCountQP: string | undefined): number | null => {
    if (!retryCountQP) {
        return null;
    }

    const retryCount = parseInt(retryCountQP, 10);
    return isNaN(retryCount) ? null : retryCount;
};

const isPageProgram: (type: PageType) => boolean = (type) =>
    type === PAGE_TYPE_PROGRAMS || type === PAGE_TYPE_PROGRAMS_TRAILER;

const ensureQueryParam = (q: string | string[] | undefined) => {
    if (!q) return;
    if (Array.isArray(q)) return q[0];
    return q;
};
const parsePage = (page: string | undefined): number | undefined => {
    if (!page) return;
    const parsed = parseInt(page, 10);
    if (isNaN(parsed)) return;
    return parsed;
};

const isTrailer = (queryTrailer: string | string[] | undefined): boolean => {
    if (!queryTrailer) return false;
    if (Array.isArray(queryTrailer)) return queryTrailer[0] === 'true';
    return queryTrailer === 'true';
};

const getUrlFromRequest = (req: IncomingMessage): URL | undefined => {
    const url = req.url;
    if (!url) {
        return undefined;
    }
    try {
        const parsed = new URL(url, `${req.headers['x-forwarded-proto']}://${req.headers.host}`);
        return parsed;
    } catch {
        return undefined;
    }
};

const buildRedirectSearchParams = (requestedUrl?: URL, isTrailer?: boolean): URLSearchParams => {
    const searchParams = new URLSearchParams();
    if (requestedUrl) {
        const currentSearchParams = new URLSearchParams(requestedUrl.search);
        currentSearchParams.forEach((value, key) => {
            searchParams.append(key, value);
        });
    }
    if (isTrailer) {
        searchParams.set('trailer', 'true');
    }
    return searchParams;
};

const buildRedirectUrl = (locale: Locale, identifiers: string[], slug: string, searchParams: URLSearchParams) =>
    `/${locale}/videos/${identifiers[0]}/${slug}/${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`;

export const getServerSideProps = async ({
    locale,
    query,
    req,
    res,
}: GetServerSidePropsContext): Promise<EmacServerSideProps<Props>> => {
    const ensuredLocale = locale && isLocale(locale) ? locale : 'fr';
    const t = ensureQueryParam(query.t);
    const retryCountQp = ensureQueryParam(query.retryCount);
    const retryCount = extractRetryCountFromQuery(retryCountQp);
    const geoblocking = getGeoblockingFromRequest(req);
    const mamiBaseUrl = env.MAMI_BASE_URL;
    const tcStartFrom = extractTcStartFromFromQuery(t);
    const abvGroups = getAbGroupsFromRequest(req);
    const validatedAge = getValidatedAgeFromRequest(req);
    const phrases = await loadPhrases(ensuredLocale);
    const { translate } = translateFactory(ensuredLocale, phrases);
    // get the identifiers from the query
    const identifiers = ensureQueryIdentifiers(query.identifiers);
    const page = parsePage(ensureQueryParam(query.page));
    const previewDate = ensurePreviewDate(query.previewDate);
    const { code, type, url, redirect } = resolveParamsFromVideosIdentifiers(
        ensuredLocale,
        identifiers,
        !!query.trailer,
    );

    newrelic.setTransactionName(`videos/${type}`);

    if (type === 'subcategories' && redirect) {
        return {
            redirect: {
                permanent: true,
                destination: `/${locale}/videos/${redirect}`,
            },
        };
    }

    const videoDataPromise = fetchVideoData({
        locale: ensuredLocale,
        page,
        geoblocking: geoblocking,
        abvGroups: abvGroups || '',
        translate,
        params: { previewDate },
        url,
        redirect,
        type,
    });

    const [videoResult, playerDataResult] = await Promise.all([
        videoDataPromise,
        isPageProgram(type)
            ? fetchProgramPlayerData({
                  programId: identifiers[0],
                  isTrailer: isTrailer(query.trailer),
                  locale: ensuredLocale,
                  geoblocking,
                  abvGroups: abvGroups || '',
                  validatedAge,
              })
            : Promise.resolve({ playerConfig: null, playerPlaylist: null }),
    ]);

    res.statusCode = getHttpStatusCode(videoResult);

    const props: Props = {
        geoblocking,
        initialType: type,
        mamiBaseUrl,
        tcStartFrom,
        retryCount,
        abvGroups: abvGroups || '',
        forceTrailer: isTrailer(query.trailer),
        apiPlayerConfig: playerDataResult?.playerConfig || null,
        apiPlayerPlaylist: playerDataResult?.playerPlaylist || null,
    };

    if (isOk(videoResult)) {
        const { value: page } = videoResult;
        if (shouldRedirectToTheRightSlug(identifiers, page, type)) {
            const requestedUrl = getUrlFromRequest(req);
            const searchParams = buildRedirectSearchParams(requestedUrl, isTrailer(query.trailer));
            const redirectUrl = buildRedirectUrl(ensuredLocale, identifiers, page.slug, searchParams);
            return {
                redirect: {
                    permanent: true,
                    destination: redirectUrl,
                },
            };
        }

        const program = getProgramFromZones(page.zones);

        if (!program && isPageProgram(type)) {
            return { notFound: true };
        }
    }

    const ageRestriction = playerDataResult?.playerConfig?.attributes?.restriction?.ageRestriction;
    const hasAgeRestriction = ageRestriction == 'ADULT' || ageRestriction == 'WARNING';
    res.setHeader('Vary', [...env.VARY_HEADERS, hasAgeRestriction ? 'x-validated-age' : ''].join(', '));

    // Pass data to the page via props
    return {
        props: {
            locale: ensuredLocale,
            type: 'emac',
            code: (code as PageCode | null) ?? 'UNKNOWN',
            domain: 'replay',
            props: {
                ...props,
                page: videoResult,
            },
        },
    };
};
